.bg {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}

.spinner {
    @size-spinner: 10vw;
    @stroke-width-logo: 0.05 * @size-spinner;
    @color-primary: #FF5252;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0;

    @duration-appear: 0.5s;

    animation: preloaderSpinnerAppear @duration-appear both ease-in;

    @keyframes preloaderSpinnerAppear {
        0% { transform: translate(-50%, -50%) scale(0.9, 0.9); }
        100% { opacity: 1; }
    }

    &.loaded {
        @duration-loaded: 0.5s;
        animation: none;
        transition: all @duration-loaded ease-out;
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.1, 1.1);

        .logoS {
            animation: none;
            transition: transform @duration-loaded ease-out;
            transform: none;
        }
    }

    .logoS {
        display: inline-block;
        position: relative;
        width: @size-spinner;
        height: @size-spinner;
        animation-fill-mode: both;
        animation: preloaderSpinner @duration-appear ease-in, preloaderSpinner 0.5s linear @duration-appear infinite;

        @keyframes preloaderSpinner {
            0% { transform: rotate(-360deg); }
        }

        .round {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border: @stroke-width-logo solid;
            border-color: @color-primary transparent;
            border-radius: 50%;
        }

        .line {
            position: absolute;
            height: @stroke-width-logo;
            width: 100%;
            top: calc(~"50%" - (@stroke-width-logo / 2));
            background-color: @color-primary;
            border-radius: @stroke-width-logo / 2;
            transform: rotate(45deg);
        }
    }
}

